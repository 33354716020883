<template>
  <div>
    <div class="pc-wrap" style="position: relative">
      <Swiper ref="banner" class="banner" :options="bannerOptions">
        <SwiperSlide v-for="(item, index) in banner" :key="index">
          <img :src="item.pic" @click="$jump(item.url)" />
        </SwiperSlide>
      </Swiper>
      <div v-if="bannerSize > 1" class="swiper-pagination">
        <span
          v-for="(item, index) in bannerSize"
          :key="index"
          class="swiper-pagination-item"
          :class="{ on: bannerIndex == index }"
        ></span>
      </div>
    </div>

    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">集团介绍</div>
        <div class="subtitle">GROUP INTRODUCTION</div>
        <div class="flex intro">
          <div class="flex-con mr-20">
            <div class="intro-title">关于我们</div>
            <div class="content" v-html="group.content"></div>
            <div
              v-if="group.url"
              class="link phone-hide"
              @click="$jump(group.url)"
            >
              进一步了解 >
            </div>
          </div>
          <div v-if="group.pic" class="flex-con">
            <img :src="group.pic" alt="" />
          </div>
          <div
            v-if="group.url"
            class="link phone-show text-c mt-20"
            @click="$jump(group.url)"
          >
            进一步了解 >
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">企业文化</div>
        <div class="subtitle">ENTERPRISE CULTURE</div>
        <img
          src="https://img.runzehui.net/2022/01/12/c05b6202201121648406523.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">创始人简介</div>
        <div class="subtitle">FOUNDER INTRODUCTION</div>
        <img
          src="https://img.runzehui.net/2022/01/12/60d50202201121643589206.jpg"
          alt=""
        />
      </div>
    </div>

    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">我们正在做的事 唤醒沉睡的消费者</div>
        <div class="subtitle">WHAT WE DO</div>
        <div>
          <div class="card-nav flex">
            <div
              v-for="(item, index) in cardNavs"
              :key="index"
              class="card-nav-item flex-con"
              :class="{ on: navIndex == index }"
              @click="navIndex = index"
            >
              {{ item.label }}
            </div>
          </div>
          <img :src="cardBanner" alt="" />
        </div>
      </div>
    </div>

    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">值得信赖的润泽汇</div>
        <div class="subtitle">TRUSTWORTHY</div>
        <div class="prize">
          <div class="prize-col">
            <div class="prize-title">荣誉奖项：</div>
            <div class="prize-text">中国白酒酒体设计大奖</div>
            <div class="prize-text">国家评委感官质量奖</div>
            <div class="prize-text">布鲁塞尔国际金奖</div>
            <div class="prize-text">贵州绿色生态优秀企业</div>
            <div class="prize-text">贵州省著名商标</div>
            <div class="prize-text">中国酒文化副会长单位</div>
            <div class="prize-line"></div>
            <div class="prize-title">软件著作权</div>
            <div class="prize-text">润泽汇APP软件V1.0</div>
            <div class="prize-text">润泽汇财务结算系统V1.0</div>
            <div class="prize-text">润泽汇电商商城小程序软件V1.0</div>
            <div class="prize-text">润泽汇店铺管理系统V1.0</div>
            <div class="prize-text">润泽汇订单管理系统V1.0</div>
            <div class="prize-text">润泽汇系统管理平台V1.0</div>
            <div class="prize-text">润泽汇汇精彩平台V1.0</div>
            <div class="prize-text">润泽汇会员管理系统V1.0</div>
            <div class="prize-text">润泽汇客服管理系统V1.0</div>
            <div class="prize-text">润泽汇营销管理系统V1.0</div>
            <div class="prize-text">润泽汇商户管理系统V1.0</div>
            <div class="prize-text">润泽汇商品管理系统V1.0</div>
            <div class="prize-text">润泽汇物流管理系统V1.0</div>
            <div class="prize-text">润泽汇系统监测平台V1.0</div>
          </div>
          <div class="prize-col-2">
            <img
              class="mb-10"
              src="https://img.runzehui.net/2022/01/12/9b68f202201121651472101.jpg"
              alt=""
            />
            <img
              src="https://img.runzehui.net/2022/01/12/05e60202201121652105470.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container pc-wrap">
      <div class="pc-content">
        <div class="title">自营商品展示</div>
        <div class="subtitle">PROPRIETARY</div>
        <div>
          <img
            src="https://img.runzehui.net/2022/01/12/2596e202201121654115267.jpg"
            alt=""
          />
          <img
            class="mt-20"
            src="https://img.runzehui.net/2022/01/12/7de1a202201121654362161.jpg"
            alt=""
          />
          <img
            class="mt-20"
            src="https://img.runzehui.net/2022/01/12/c8f6b202201121654594051.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      banner: [
        {
          pic: "https://img.runzehui.net/2022/01/17/3ca15202201171446062854.jpg",
        }
      ],
      bannerOptions: {
        on: {
          slideChangeTransitionStart: (e) => {
            this.bannerIndex = e.activeIndex;
          },
        },
      },
      bannerIndex: 0,
      group: {
        content: `<span style="font-weight: bold">润泽汇数字商业集团</span>
中开协数字化产业链链长单位
国家高新技术企业
新华网溯源中国合作企业
湖南省制造业数字化转型生态供给资源池服务机构整体解决方案服务商
润泽汇数字商业集团是企业数字化转型的服务商、运营商、投资商，以“赋能、链接、利他、共享”为经营理念，以数信平台为核心，以大数据为抓手，为客户提供数字化私域整体解决方案，助力社团、企业、精英高效积累数据资产，助推企业实现数字化转型。
润泽汇全力打造的数字私域社交平台——数信，通过私域通，同客户共同打造自己的数据中台，实现从公域到私域的完美转化，通过数据确权和区块链技术产生数币，消费者获得数字身份，永久获益，数币可用于兑换商品和服务，助力社团、企业、精英通过数币进行流量变现，实现营收倍增，价值延伸。`,
        pic: "https://img.runzehui.net/2022/01/12/11d4120220112163537465.jpg",
      },
      cardNavs: [
        {
          label: "集团理念",
          url: "https://img.runzehui.net/2022/01/12/c9100202201121646142795.jpg",
        },
        {
          label: "模式创新",
          url: "https://img.runzehui.net/2022/01/12/38a7c202201121646424789.jpg",
        },
      ],
      navIndex: 0,
    };
  },
  computed: {
    bannerSize() {
      return this.banner.length;
    },
    cardBanner() {
      return this.cardNavs[this.navIndex].url;
    },
  },
};
</script>

<style scoped lang="scss">
img {
  display: block;
  width: 100%;
}
.banner {
  overflow: hidden;
}
.swiper-pagination {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1;

  .swiper-pagination-item {
    display: inline-block;
    width: 36px;
    height: 6px;
    background: #42404e;
    margin: 10px;

    &.on {
      background: #fff;
    }
  }
}
.intro {
  .intro-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .content {
    font-size: 14px;
    line-height: 2;
    letter-spacing: 2px;
    margin-bottom: 20px;
    width: 100%;
    white-space: pre-wrap;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.card-nav-item {
  height: 56px;
  font-size: 20px;
  font-weight: bold;
  line-height: 56px;
  text-align: center;
  color: #afafaf;
  background-color: #f7f9f8;

  &.on {
    color: #000;
    background-color: #dcdedd;
  }
}

.prize {
  display: flex;
  padding: 10px;
  background-color: #efefef;

  .prize-col {
    flex: 1;
    padding-left: 20px;
  }

  .prize-col-2 {
    flex: 2;
  }

  .prize-title {
    margin-top: 40px;
    margin-bottom: 10px;
    margin-left: 60px;
    font-size: 18px;
    font-weight: bold;
  }
  .prize-text {
    margin-left: 60px;
    font-size: 13px;
    line-height: 1.8;
  }

  .prize-line {
    margin-top: 40px;
    margin-left: 60px;
    display: block;
    width: 60px;
    height: 1px;
    background-color: #666;
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 320px) and (max-width: 430px) {
  .intro {
    display: block;
    .intro-title {
      display: inline-block;
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 10px;
      padding: 2px 10px;
      color: #fff;
      background: #011167;
      border-radius: 5px;
    }
  }

  .prize {
    display: block;
  }
}
</style>